import React from 'react'
import { useNavigate } from '@reach/router'
import styled from 'styled-components'
import { Image, breakpoints } from '@ecommerce/shared'
import ErrorPage from '../templates/ErrorPage'
import withPageTransition from '../components/withPageTransition'

const logo =
  'https://images.ctfassets.net/16npdkkoi5mj/4EAocVvRjUs3wHYN7PiSDB/f89865bb82569f8a649d1c07fbfbfbd5/CCO_-_BLANCO_-_SVG.svg'

type Props = {
  onRetry: () => void
}

const Wrapper = styled.div`
  .NetworkError__ {
    &wrapper {
      padding-bottom: 0;
      position: relative;
    }

    &image {
      display: none;
    }

    &image-container {
      position: absolute;
      bottom: 0;
      right: calc(50% - 512px);
      width: 540px;

      svg {
        position: relative;
        top: 4px;
        width: 100%;
        height: 100%;
      }
    }

    @media screen and (${breakpoints.tabletLandscape.max}) {
      &left {
        margin-bottom: 310px;
      }

      &image-container {
        right: calc(50% - 135px);
        max-width: 270px;
      }
    }

    @media screen and (${breakpoints.desktopWide.min}) {
      &__image-container {
        right: calc(50% - 680px);
      }
    }

    @media screen and (max-height: 780px) and (${breakpoints.desktop.min}) {
      &__image-container {
        max-width: 400px;
      }
    }
  }
`

const TitleWrapper = styled.div`
  font-size: 64px;

  h2 {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    span {
      height: 62px;
      display: block;
    }
  }
`

const TitleItem = styled.div`
  background: ${({ theme }) => theme.colors.primary};
  width: 45px;
  height: 45px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 5px;

  img {
    object-fit: contain;
  }
`

const Title = () => (
  <TitleWrapper>
    <h2>
      <span>¡W</span>
      <TitleItem>
        <Image src={logo} alt="Coca-Cola-logo" />
      </TitleItem>
      <TitleItem>
        <Image src={logo} alt="Coca-Cola-logo" />
      </TitleItem>
      <span>w!</span>
    </h2>
  </TitleWrapper>
)

const PageImage = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="540" height="542" viewBox="0 0 540 542">
    <g fill="none" fillRule="evenodd">
      <g fillRule="nonzero">
        <path
          fill="#FFF"
          d="M16.721 247.444L76.5 206.005c25.779-16.825 27.033-46.444 25.64-67.302-.697-11.542-3.902-22.249-6.69-31.705-.557-1.807-1.114-3.615-1.532-5.423l-17.279 4.867c.558 1.808 1.115 3.755 1.672 5.562 2.648 8.9 5.435 18.216 5.992 27.672 1.672 26.838-3.623 42.134-17.557 51.311L0 241.187l16.721 6.257z"
          transform="translate(.33) translate(403.67)"
        />
        <g>
          <path
            fill="#FFF"
            d="M5.363 15.361l10.73 31.426c.696 2.086 3.9 2.782 7.106 1.808 3.205-1.112 5.295-3.615 4.598-5.701l-10.73-31.427c-.696-2.085-3.9-2.78-7.106-1.807-3.205 1.112-5.295 3.754-4.598 5.701zM32.674 6.183l10.73 31.427c.697 2.086 3.901 2.78 7.106 1.808 3.205-1.113 5.295-3.616 4.599-5.702L44.379 2.29C43.682.204 40.478-.491 37.273.482c-3.205 1.113-5.295 3.755-4.599 5.701z"
            transform="translate(.33) translate(403.67) translate(28.777)"
          />
          <path
            fill="#FD0E22"
            d="M63.051 121.182c-19.23 6.396-40.13-3.894-46.54-23.083L.067 49.429 69.74 26.07l16.442 48.668c6.55 19.051-3.901 39.91-23.13 46.445z"
            transform="translate(.33) translate(403.67) translate(28.777)"
          />
          <path
            fill="#DD0B1C"
            d="M86.182 74.737l-2.368-6.952L14.14 91.146l2.37 6.953c6.41 19.19 27.31 29.48 46.54 23.083 19.23-6.536 29.68-27.394 23.131-46.445z"
            transform="translate(.33) translate(403.67) translate(28.777)"
          />
        </g>
      </g>
      <g transform="translate(.33) translate(.67 227)">
        <ellipse cx="269" cy="95.522" fill="#333" opacity=".5" rx="268.928" ry="67.779" />
        <g fillRule="nonzero">
          <path
            fill="#000"
            d="M463.317 65.196L461.499 110.196 434.788 109.078 436.606 64.078z"
            transform="translate(60.626)"
          />
          <path
            fill="#AEADB4"
            d="M0 113.83s21.397 38.85 56.499 25.015l123.905 4.891s7.832 5.59 15.523 4.193l100.971 4.052s6.713 3.494 13.705-1.118c0 0 68.526-9.782 115.795-44.58l8.11.28.84-20.265L1.818 69.11 0 113.83z"
            transform="translate(60.626)"
          />
          <path
            fill="#C8C6CE"
            d="M3.636 24.808S27.97-12.226 61.953 4.265l123.905 4.891s8.251-4.891 15.803-2.935l100.97 4.053s6.993-2.935 13.566 2.096c0 0 67.547 15.093 111.879 53.665l8.11.28-.838 20.263-433.53-17.19 1.818-44.58z"
            transform="translate(60.626)"
          />
          <path
            fill="#FD0E22"
            d="M211.285 -1.223L282.417 -1.221 282.419 88.56 211.287 88.559z"
            transform="translate(60.626) rotate(92.275 246.852 43.669)"
          />
          <path
            fill="#CD091C"
            d="M208.492 69.526L279.624 69.528 279.627 159.31 208.494 159.308z"
            transform="translate(60.626) rotate(92.275 244.06 114.418)"
          />
          <path
            fill="#7BB23E"
            d="M135.191 71.876L276.757 71.88 276.757 82.649 135.191 82.645z"
            transform="translate(60.626) rotate(92.275 205.974 77.263)"
          />
          <path
            fill="#000"
            d="M204.449 73.567L346.016 73.571 346.016 86.437 204.45 86.433z"
            transform="translate(60.626) rotate(92.275 275.233 80.002)"
          />
        </g>
        <path
          fill="#AEADB4"
          fillRule="nonzero"
          d="M470.941 85.6l-1.259 32.003s8.95-5.031 17.342-11.32l8.11.28.84-19.985S471.08 85.46 470.94 85.6z"
        />
        <path
          fill="#C8C6CE"
          fillRule="nonzero"
          d="M495.974 86.718l.839-20.264-8.111-.28c-7.972-6.987-16.363-12.577-16.363-12.577l-1.258 32.422c-.14-.14 25.033.559 24.893.699z"
        />
      </g>
      <path
        fill="#FFF"
        fillRule="nonzero"
        d="M362.67 542c-35.933-23.02-67.004-44.686-93.214-64.998-15.282-11.844-21.85-28.126-19.914-59.064.692-10.969 3.734-21.656 6.777-31.922.691-2.11 1.244-4.36 1.797-6.47L238.48 378c-.553 2.11-1.245.14-1.798 2.25-3.18 10.828-6.776 23.063-7.606 36.423-1.521 24.047-.138 58.22 29.041 77.485 25.024 16.607 50.854 32.555 77.49 47.842h27.064z"
        transform="translate(.33)"
      />
    </g>
  </svg>
)

const NetworkErrorPage = () => {
  const navigate = useNavigate()

  return (
    <Wrapper>
      <ErrorPage
        title="Error en conexión"
        className="NetworkError"
        image={<PageImage />}
        leftContent={{
          title: <Title />,
          text: 'Refresca tu navegador. No hay conexión a internet.',
          buttonText: 'Intenta de nuevo',
          buttonAction: () => navigate(-1),
        }}
      />
    </Wrapper>
  )
}

export default withPageTransition(NetworkErrorPage)
